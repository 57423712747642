import { useScroll, motion } from "framer-motion";
import React, { useContext, useRef } from "react";
import styled from "styled-components";
import LoggedOutSectionTitle from "./LoggedOutSectionTitle";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import MembershipSelection from "../../loggedIn/onboarding/MembershipSelection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 100px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const Underline = styled.div`
  height: 5px;
  border-radius: 5px;
  margin-top: -20px;
  width: 400px;
  margin-bottom: 50px;
  background-color: var(--accentColor);
`;

const LoggedOutMembershipSection = ({ containerRef }) => {
  const { isMobile } = useContext(Context);
  const { t } = useTranslation();
  const targetRef = useRef(null);

  const animation = useScroll({
    container: containerRef,
    target: targetRef,
    offset: ["0 1", "0.7 1"],
  });

  return (
    <Container ref={targetRef} id="membership">
      <motion.div
        style={{
          scale: isMobile ? 1 : animation.scrollYProgress,
        }}
      >
        <Main>
          <LoggedOutSectionTitle>
            {t("membershipPlanTitle")}
          </LoggedOutSectionTitle>

          <Underline />

          <MembershipSelection />
        </Main>
      </motion.div>
    </Container>
  );
};

export default LoggedOutMembershipSection;
