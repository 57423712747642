import React, { useContext } from "react";
import styled from "styled-components";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import { useTranslation } from "react-i18next";
import limitStringLength from "../../../../../controllers/limitStringLength";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 15px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NewCard = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;


const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

const FiledValue = styled.div``;


export default function ManageMembershipPlanCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let fields = [
    { type: "STRING", name: "name", label: t("adminPageMembershipTypeFormName") },
    { type: "STRING", name: "description", label: t("adminPageMembershipTypeFormDescription") },
    { type: "STRING", name: "price", label: t("adminPageMembershipTypeFormPrice") },
    { type: "STRING", name: "feature1", label: t("adminPageMembershipTypeFormFeature1") },
    { type: "STRING", name: "feature2", label: t("adminPageMembershipTypeFormFeature2") },
    { type: "STRING", name: "feature3", label: t("adminPageMembershipTypeFormFeature3") },
    { type: "STRING", name: "feature4", label: t("adminPageMembershipTypeFormFeature4") },
  ];

  if (forAddingNewItem)
    return (
      <NewCard>
        <CustomButton onClick={addItem}>{t("clickHereToAddNew")}</CustomButton>
      </NewCard>
    );

  return (
    <Card>


      <Data>
        <Name>{item.name} </Name>
        <FiledValue>
          {t("adminPageEmployeeDescription")}:{" "}
          {limitStringLength(item.description, 100)}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageMembershipTypeFormPrice")}:{" "}
          {item.price}{" "}
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton onClick={editItem}>
          {t("adminPageEmployeeEditItem")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/membership-plan"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/membership-plan"
        />
      ),
    });
  }
}
