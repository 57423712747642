import { useState } from "react";
import WithBackground from "../../../core/boilerplate/WithBackground";
import CustomToggle from "../../../helperComponents/CustomToggle";
import LoggedOutHeader from "../landingPage/LoggedOutHeader";
import styled from "styled-components";
import MaterialInput from "../../../helperComponents/MaterialInput";
import extractEventValue from "../../../../controllers/utils/extractEventValue";
import InputCard from "../../../helperComponents/InputCard";
import CustomButton from "../../../helperComponents/CustomButton";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import saveUserAuth from "../../../../controllers/auth/saveUserAuth";
import { GrGoogle } from "react-icons/gr";
import goToAuthScreen from "../../../../controllers/goToAuthScreen";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;

  @media (max-width: 900px) {
    gap: 40px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  align-items: center;
  padding-bottom: 30px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-end;
`;

export default function LoginSignupPage({ initialType }) {
  const [type, setType] = useState(initialType ? initialType : "LOGIN");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const options = [
    { value: "LOGIN", label: t("login") },
    { value: "SIGNUP", label: t("signup") },
  ];

  let loginWithGoogle = (
    <CustomButton
      onClick={goToAuthScreen}
      style={{
        color: "var(--accentColor)",
        height: "55px",
        width: "55px",
        borderRadius: "500px",
      }}
      icon={<GrGoogle />}
    ></CustomButton>
  );

  let core = (
    <InputCard
      submitButtonText={t("authSubmit")}
      onSubmit={submit}
      additionalButtons={loginWithGoogle}
    >
      <Form>
        <CustomToggle options={options} value={type} onChange={setType} />
        {getInputs()}
      </Form>
    </InputCard>
  );

  if (loading)
    core = (
      <InputCard>
        <LoadingSection />
      </InputCard>
    );

  return (
    <WithBackground backgroundImage="/dumbbells.jpg">
      <Container>
        <LoggedOutHeader />

        {core}
      </Container>
    </WithBackground>
  );

  function submit() {
    if (type == "LOGIN") return login();
    signup();
  }

  async function login() {
    if (!email) return window.popupAlert("Please input the email");
    if (!password) return window.popupAlert("Please input the password");

    try {
      setLoading(true);
      let authData = await serverLine.post("/email-login", { email, password });

      setLoading(false);
      saveUserAuth(authData);
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }

  async function signup() {
    if (!email) return window.popupAlert("Please input the email");
    if (!password) return window.popupAlert("Please input the password");

    if (password !== confirmPassword)
      return window.popupAlert("Confirm password did not match");

    try {
      setLoading(true);
      let authData = await serverLine.post("/email-signup", {
        email,
        password,
      });

      setLoading(false);
      saveUserAuth(authData);
    } catch (e) {
      setLoading(false);
      window.popupAlert(e.message);
    }
  }

  function getInputs() {
    if (type == "LOGIN")
      return (
        <Inputs>
          <MaterialInput
            label={t("authEmailInputLabel")}
            // placeholder={"Type Email Here"}
            value={email}
            onChange={extractEventValue(setEmail)}
          />
          <MaterialInput
            type="password"
            label={t("authPasswordInputLabel")}
            // placeholder={"Type Password Here"}
            value={password}
            onChange={extractEventValue(setPassword)}
          />
          <CustomButton variant="minimal-underlined" href="/forgot-password">
            {t("authForgotPassword")}
          </CustomButton>
        </Inputs>
      );

    if (type == "SIGNUP")
      return (
        <Inputs>
          <MaterialInput
            label={t("authEmailInputLabel")}
            // placeholder={"Type Email Here"}
            value={email}
            onChange={extractEventValue(setEmail)}
          />
          <MaterialInput
            type="password"
            label={t("authPasswordInputLabel")}
            // placeholder={"Type Password Here"}
            value={password}
            onChange={extractEventValue(setPassword)}
          />
          <MaterialInput
            type="password"
            label={t("authConfirmPasswordInputLabel")}
            // placeholder={"Type Confirm Password Here"}
            value={confirmPassword}
            onChange={extractEventValue(setConfirmPassword)}
          />
        </Inputs>
      );
  }
}
