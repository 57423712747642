import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageMembershipPlanCard from "./ManageMembershipPlanCard";

export default function ManageMembershipPage({ dashboardType }) {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageMembershipPlansTitle")}
      path="membership-plans"
      CardComponent={ManageMembershipPlanCard}
      dashboardType={dashboardType}
      allowCreation={true}
    />
  );
}
