import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MembershipSelectionCard from "./MembershipSelectionCard";
import { serverLine } from "../../../../controllers/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const PlanFeatures = styled.div`
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
  font-size: 16px;
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 200px;
`;

const PlanFeature = styled.div`
  margin-bottom: 0;
  display: flex;

  &::before {
    background-color: rgba(0, 0, 0, 0.5);
    height: 20px;
    width: 20px;
    content: "✓";
    color: #ced400;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
`;

const MembershipSelection = ({ value, onChange }) => {
  const [plans, setPlans] = useState(null);

  useEffect(() => {
    serverLine.get("/membership-plans").then(setPlans);
  }, []);

  if (!plans)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      {plans.map((item) => (
        <MembershipSelectionCard
          title={item.name}
          desc={item.description}
          price={item.price + " CAF / Month"}
          value={value}
          onChange={onChange}
          name={item.name.toUpperCase()}
          features={
            <PlanFeatures>
              <PlanFeature>{item.feature1}</PlanFeature>
              <PlanFeature>{item.feature2}</PlanFeature>
              <PlanFeature>{item.feature3}</PlanFeature>
              <PlanFeature>{item.feature4}</PlanFeature>
            </PlanFeatures>
          }
        />
      ))}
    </Container>
  );
};

export default MembershipSelection;
