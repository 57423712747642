import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageStoreItemCard from "./ManageStoreItemCard";

export default function ManageStorePage({ dashboardType }) {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageStoreTitle")}
      path="store-items"
      CardComponent={ManageStoreItemCard}
      dashboardType={dashboardType}
      allowCreation={true}
    />
  );
}
