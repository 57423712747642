import { GoArrowRight } from "react-icons/go";
import CustomButton from "./CustomButton";

export default function PrimaryButton({
  onClick,
  children,
  style = {},
  textStyle,
  icon = <GoArrowRight />
}) {
  if (!textStyle) textStyle = {};
  return (
    <CustomButton
      onClick={onClick}
      iconStyle={{ fontSize: "27px" }}
      textStyle={{ fontSize: "18px", fontWeight: "900", fontFamily: "Montserrat", ...textStyle }}
      onHoverStyle={`

        transform:scale(0.95);

        `}
      style={{
        color: "var(--bgColor)",
        borderRadius: "100px",
        width: "200px",
        gap: "30px",
        border: "none",
        flexDirection: "row-reverse",
        fontWeight: "900",
        background: "linear-gradient(90deg, #ced400 0%, #75a500 100%)",
        transition: "all 0.25s ease-in-out",
        boxShadow: "rgb(120 166 0 / 72%) 1px 1px 90px 1px",
        textTransform: "capitalize",
        ...style,
      }}
      icon={icon}
    >
      {children}
    </CustomButton>
  );
}
