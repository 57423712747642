import { useEffect, useReducer, useState } from "react";
import reducer from "../../../reducer.js";
import Context from "../../../Context.js";
import DynamicForm from "../../helperComponents/dynamicForm/DynamicForm.js";
import getUserID from "../../../controllers/getUserID.js";
import { RiGoogleFill } from "react-icons/ri/index.js";
import goToAuthScreen from "../../../controllers/goToAuthScreen.js";
import { useNavigate } from "react-router-dom";
import { serverLine } from "../../../controllers/serverLine.js";
import logout from "../../../controllers/logout.js";
import isLoggedIn from "../../../controllers/isLoggedIn.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import goTo from "../../../controllers/goTo.js";
import updateRouteState from "../../../controllers/updateRouteState.js";
import LoadingSection from "../../helperComponents/LoadingSection.js";
import getHostName from "../../../controllers/getHostName.js";
import VerifyEmail from "../../pages/loggedIn/onboarding/VerifyEmail.js";
import AskNameAndGenderPage from "../../pages/loggedIn/onboarding/AskNameAndGenderPage.js";
import AskDobPage from "../../pages/loggedIn/onboarding/AskDobPage.js";
import AskPhoneNumberPage from "../../pages/loggedIn/onboarding/AskPhoneNumberPage.js";
import AskWeightPage from "../../pages/loggedIn/onboarding/AskWeightPage.js";
import AskMembershipPage from "../../pages/loggedIn/onboarding/AskMembershipPage.js";
import AskPhotoPage from "../../pages/loggedIn/onboarding/AskPhotoPage.js";

window.nonReactLikeStatus = {};

function Boilerplate({ children }) {
  const [loggedInUserID, setLoggedInUserID] = useState(null);
  const [formData, setFormData] = useState(false);
  const [notificationData, setNotificationData] = useState({
    general: 0,
    work: 0,
  });
  const [popupFeedItem, setPopupFeedItem] = useState(null);
  const navigate = useNavigate();
  const alert = (message) => {
    toast(message);
  };
  const [colorMode, setColorMode] = useState("DARK");
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [state, dispatch] = useReducer(reducer, {});
  const [scrollData, setScrollData] = useState(null);
  const [adminSettings, setAdminSettings] = useState({});

  let currentPath = window.location.pathname + window.location.search;
  const [currentRoute, setCurrentRoute] = useState(currentPath);
  let initialNonPopupRoute = currentPath;
  const [nonPopupRoute, setNonPopupRoute] = useState(initialNonPopupRoute);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    updateAdminSettings();
  }, []);

  function updateAdminSettings(newItemData) {
    if (newItemData) {
      setAdminSettings(newItemData);
    } else {
      serverLine.get("/settings").then(setAdminSettings);
    }
  }

  window.setNonPopupRoute = setNonPopupRoute;
  window.setCurrentRoute = setCurrentRoute;
  window.setForm = setForm;
  window.navigate = navigate;
  window.doAlert = alert;
  window.loggedInUserID = loggedInUserID;
  window.loggedInUser = loggedInUser;
  window.homeLink = window.location.protocol + "//" + getHostName();
  window.updateLoggedInUser = updateLoggedInUser;

  function updateNotificationData() {
    serverLine.get("/notification-count").then(setNotificationData);
  }

  useState(() => {
    let theFunc = (theEvent) => {
      let newURl = window.location.pathname + window.location.search;

      updateRouteState(newURl);
    };

    window.addEventListener("popstate", theFunc);

    return () => {
      window.removeEventListener("popstate", theFunc);
    };
  }, []);

  useEffect(() => {
    if (loggedInUserID) {
      // updateNotificationData();
      // if (window.notifInterval) {
      //   window.clearInterval(window.notifInterval);
      // }
      // window.notifInterval = window.setInterval(() => {
      //   updateNotificationData();
      // }, 30 * 1000);
    }
  }, [loggedInUserID]);

  useEffect(() => {
    let userID = getUserID();

    if (userID) {
      setLoggedInUserID(userID);
      updateLoggedInUser();
    } else {
      setLoggedInUserID(false);
    }
  }, []);

  useEffect(() => {
    removeFormQuery();
    backFeatures();

    let data = {};
    dispatch({ type: "NEW_STATE", value: data });

    let newStatus = localStorage.getItem("wellness-color-mode");
    if (!newStatus) newStatus = "DARK";
    updateDarkMode(newStatus);
  }, []);

  // console.log(loggedInUser);

  if (isLoggedIn()) {
    if (!loggedInUser) {
      children = <LoadingSection />;
    } else {
      let onboardingComponent = getOnboardingComponent();

      if (onboardingComponent) children = onboardingComponent;
      // if (!loggedInUser.onboarding) {
      //   children = <AskBasicInfo />;
      // } else {
      //   if (!loggedInUser.onboarding.basicInfoAdded)
      //     children = <AskBasicInfo />;
      // }
    }
  }

  window.popupAlert = alert;

  window.popupError = (err) => {
    if (err) {
      if (err.message) {
        alert(err.message);
      } else {
        alert("An Error Occurred");
      }
    }
  };

  return (
    <Context.Provider
      value={{
        innerWidth,
        isMobile: innerWidth <= 900,
        adminSettings,
        updateAdminSettings,
        //
        scrollData,
        setScrollData,
        nonPopupRoute,
        setNonPopupRoute,
        currentRoute,
        setLoggedInUser,
        promptLogin,
        loggedInUserID,
        popupFeedItem,
        setPopupFeedItem,
        notificationData,
        setNotificationData,
        updateNotificationData,
        state,
        popupAlert: alert,
        updateLoggedInUser: updateLoggedInUser,
        loggedInUser: loggedInUser,
        dispatch,
        updateDarkMode,
        colorMode,
        setForm: setForm,
      }}
    >
      <DynamicForm setForm={setForm} data={formData} />

      {children}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Context.Provider>
  );

  function updateDarkMode(newStatus) {
    setColorMode(newStatus);
    localStorage.setItem("wellness-color-mode", newStatus);
    setColors(newStatus);
  }

  function updateLoggedInUser() {
    try {
      serverLine.get("/logged-in-user").then(setLoggedInUser);
    } catch (e) {
      alert.show(e.message);
      console.log("First Fetch Error----------");
      logout();
    }
  }

  function getOnboardingComponent() {
    if (!loggedInUser.emailConfirmed) return <VerifyEmail />;

    if (!loggedInUser.firstName) return <AskNameAndGenderPage />;
    if (!loggedInUser.gender) return <AskNameAndGenderPage />;

    if (!loggedInUser.dateOfBirth) return <AskDobPage />;

    if (!loggedInUser.phoneNumber) return <AskPhoneNumberPage />;

    if (!loggedInUser.weight && !loggedInUser.skipWeight) return <AskWeightPage />;

    if (!loggedInUser.membershipType && !loggedInUser.skipMembership) return <AskMembershipPage />;

    if (!loggedInUser.profileImage && loggedInUser.skipProfileImage) return <AskPhotoPage />;
  }

  function setColors(newVal) {
    let darkTheme = {
      bgColor: "#000000 ",
      foreground: "#111111",
      bgColorDark: "#000000",
      bgColorLight: "#111111",
      bgColor2: "#000000",
      color: "#ffffff",
      accentBorder: "#d1da3078",
      accentColor: "#D1DA30",
      accentLight: "#cbd065",
      accentVeryLight: "rgb(209 218 48 / 37%)",
      translucentLight: "rgb(212 226 227 / 5%)",
      translucent: "rgb(212 226 227 / 15%)",
      translucentHard: "rgba(255,255,255,0.2)",
      mainGradient: "linear-gradient(90deg, #ced400 0%, #75a500 100%)",
      glassGradient:
        "linear-gradient(24deg, rgba(255,255,255,0.1), rgba(255,255,255,0.3))",
      highlightGradient:
        "linear-gradient(45deg, #d4cfcf1c, #000000, #c8c8c824)",
      highlightBorder: "1px solid #d1da303b",
    };

    let colors = darkTheme;

    // if (newVal) {
    //   if (newVal === "DARK")
    //     colors = {
    //       bgColor: "#111111",
    //       bgColorDark: "#000000",
    //       bgColorLight: "#222222",
    //       bgColor2: "#000000",
    //       color: "#ffffff",
    //       accentColor: "#8c9fbd",
    //       translucent: "rgb(212 226 227 / 6%)",
    //       translucentHard: "rgba(255,255,255,0.2)",
    //     };
    // }

    document.documentElement.style.setProperty(
      "--highlightBorder",
      colors.highlightBorder
    );

    document.documentElement.style.setProperty(
      "--highlightGradient",
      colors.highlightGradient
    );

    document.documentElement.style.setProperty(
      "--translucentLight",
      colors.translucentLight
    );

    document.documentElement.style.setProperty(
      "--accentBorder",
      colors.accentBorder
    );

    document.documentElement.style.setProperty(
      "--mainGradient",
      colors.mainGradient
    );

    document.documentElement.style.setProperty(
      "--veryLightShadow",
      colors.veryLightShadow
    );

    document.documentElement.style.setProperty(
      "--glassGradientHeader",
      colors.glassGradientHeader
    );

    document.documentElement.style.setProperty(
      "--primaryColor",
      colors.primaryColor
    );

    document.documentElement.style.setProperty(
      "--glassBorder",
      colors.glassBorder
    );

    document.documentElement.style.setProperty(
      "--glassGradient",
      colors.glassGradient
    );

    document.documentElement.style.setProperty(
      "--glassGradientLight",
      colors.glassGradientLight
    );

    document.documentElement.style.setProperty(
      "--glassGradientHard",
      colors.glassGradientHard
    );

    document.documentElement.style.setProperty(
      "--lightShadow",
      colors.lightShadow
    );

    document.documentElement.style.setProperty(
      "--hardShadow",
      colors.hardShadow
    );

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--accentColor",
      colors.accentColor
    );

    document.documentElement.style.setProperty(
      "--accentLight",
      colors.accentLight
    );

    document.documentElement.style.setProperty(
      "--accentVeryLight",
      colors.accentVeryLight
    );

    document.documentElement.style.setProperty(
      "--bgColorDark",
      colors.bgColorDark
    );

    document.documentElement.style.setProperty("--bgColor", colors.bgColor);
    document.documentElement.style.setProperty(
      "--bgColorLight",
      colors.bgColorLight
    );
    document.documentElement.style.setProperty("--bgColor2", colors.bgColor2);
    document.documentElement.style.setProperty("--color", colors.color);
    document.documentElement.style.setProperty(
      "--translucent",
      colors.translucent
    );
    document.documentElement.style.setProperty(
      "--translucentHard",
      colors.translucentHard
    );
  }

  function promptLogin() {
    setFormData({
      title: "Please Login",
      buttons: [
        {
          icon: <RiGoogleFill />,
          name: "Login / Signup",
          onClick: goToAuthScreen,
        },
      ],
    });
  }

  function backFeatures() {
    window.addEventListener("popstate", (event) => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    const formPage = getUrlQuery("formPage");
    if (!formPage) {
      setFormData(null);
    }
  }

  function getUrlQuery(field) {
    if (typeof window == "undefined") return null;
    const queryString = window.location.search;
    const urlParamsForm = new URLSearchParams(queryString);
    return urlParamsForm.get(field);
  }

  function setForm(data, noPathChange) {
    if (noPathChange) {
      return setFormData({ data, noPathChange });
    }

    let path = window.location.pathname;
    let queryObj = getUrlQueryObject();

    if (getUrlQuery("formPage") && !data) {
      closeForm();
    } else if (!getUrlQuery("formPage")) {
      queryObj.formPage = "true";

      let thePath = removeLastSlash(path) + queryObjToUrl(queryObj);

      goTo(thePath)();

      setFormData({ data, noPathChange });
    } else {
      setFormData({ data, noPathChange });
    }
  }

  function removeLastSlash(urlString) {
    if (urlString[urlString.length - 1] == "/") {
      return urlString.slice(0, urlString.length - 1);
    } else {
      return urlString;
    }
  }

  function queryObjToUrl(queryInput) {
    let newQueryUrl = "/?";
    let i = 0;
    let queryLen = Object.keys(queryInput).length;
    for (let key in queryInput) {
      if (queryInput[key]) {
        if (i == queryLen - 1) {
          newQueryUrl += `${key}=${queryInput[key]}`;
        } else {
          newQueryUrl += `${key}=${queryInput[key]}&`;
          i++;
        }
      }
    }

    return newQueryUrl;
  }

  function getUrlQueryObject() {
    let data = {};
    let raw = window.location.search.replace(/\?/gi, "").replace(/\//gi, "");
    if (!raw) return data;
    raw = raw.split("&");
    for (let itm of raw) {
      if (!itm) continue;
      itm = itm.split("=");
      if (itm.length == 2) {
        if (itm[1].trim()) {
          data[itm[0]] = itm[1];
        }
      }
    }
    return data;
  }

  function removeFormQuery() {
    //this prevents bug
    //what bug?
    //if url already has form query then
    //on setFormData query won't be pushed

    if (getUrlQuery("formPage")) {
      let path = window.location.pathname;
      let queryObj = getUrlQueryObject();
      delete queryObj.formPage;
      let newPath = "/";

      let queryPath = queryObjToUrl(queryObj);

      if (queryPath == "/?") queryPath = "";

      if (path === "/") {
        newPath = queryPath;
      } else {
        if (path[path.length - 1] == "/" && queryPath[0] == "/") {
          //if query path starts with / and the url path end with /
          queryPath = queryPath.slice(1, queryPath.length);
        }
        newPath = path + queryPath;
      }

      if (newPath[newPath.length - 1] == "/") {
        newPath = newPath.slice(0, newPath.length - 1);
      }

      console.log("newPath", newPath, queryPath);
      if (!newPath) newPath = "/";
      goTo(newPath, { isReplace: true })();
    }
  }

  function closeForm() {
    //sometimes it takes a few seconds to push the router
    //if user presses back before then user might go 2 pages back
    if (getUrlQuery("formPage")) {
      goTo(-1)();
    }
  }
}

export default Boilerplate;
