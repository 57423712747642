import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageEmployeesCard from "./ManageEmployeesCard";

export default function ManageEmployeesPage({ dashboardType }) {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={
        dashboardType == "EMPLOYEE_DASHBOARD"
          ? t("me")
          : t("adminPageEmployeesTitle")
      }
      path="employees"
      CardComponent={ManageEmployeesCard}
      dashboardType={dashboardType}
      allowCreation={true}
    />
  );
}
