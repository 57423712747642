import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MembershipSelection from "../onboarding/MembershipSelection";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 30px;
  padding: 100px 0;
  padding-top: 0;
`;

const Title = styled.h1``;

const LoggedInMembershipSection = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>Membership Plans</Title>
      <MembershipSelection />
    </Container>
  );
};

export default LoggedInMembershipSection;
