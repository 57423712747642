import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageOrderCard from "./ManageOrderCard";

export default function ManageOrderPage({ dashboardType }) {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageOrdersTitle")}
      path="orders"
      CardComponent={ManageOrderCard}
      dashboardType={dashboardType}
      // allowCreation={true}
    />
  );
}
