import React, { useContext } from "react";
import styled from "styled-components";
import getImageURL from "../../../../../controllers/getImageURL";
import CustomButton from "../../../../helperComponents/CustomButton";
import Context from "../../../../../Context";
import AddOrEditForm from "../AddOrEditForm";
import formatDate from "../../../../../controllers/formatDate";
import goTo from "../../../../../controllers/goTo";
import { useTranslation } from "react-i18next";

const Card = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;

  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const NewCard = styled.div`
  background: var(--translucent);
  border-radius: 15px;
  padding: 10px;
  width: 350px;
  overflow: hidden;
  position: relative;
  transition: all 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Name = styled.div`
  font-weight: 900;
  font-size: 19px;
`;

const Status = styled.div`
  /* padding: 5px 10px; */
  border-radius: 5px;
  text-transform: capitalize;
  font-weight: 500;
`;

const ProfileText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-direction: column;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FiledValue = styled.div``;

export default function ManageActivityCard({
  item,
  index,
  items,
  setItems,
  forAddingNewItem,
}) {
  const { setForm } = useContext(Context);
  const { t } = useTranslation();

  let membershipType = [
    { value: "SILVER", label: t("adminPageActivityFormSilver") },
    { value: "GOLD", label: t("adminPageActivityFormGold") },
    { value: "PLATINUM", label: t("adminPageActivityFormPlatinum") },
  ];

  let participationTypeOptions = [
    { value: "SOLO", label: t("adminPageActivityFormSolo") },
    { value: "GROUP", label: t("adminPageActivityFormGroup") },
    { value: "PRIVATE_GROUP", label: t("adminPageActivityFormPrivateGroup") },
  ];

  let fields = [
    {
      type: "NUMBER",
      name: "duration",
      label: t("adminPageActivityFormDuration"),
    },
    {
      type: "NUMBER",
      name: "seats",
      label: t("adminPageActivityFormNumberOfSeats"),
    },
    {
      type: "NUMBER",
      name: "priceForNonMember",
      label: t("adminPageActivityFormPriceForNonMember"),
    },
    {
      type: "UPCOMING_DATES",
      name: "date",
      label: t("adminPageActivityFormDate"),
    },
    { type: "HOUR", name: "hour", label: t("adminPageActivityFormStartTime") },
    {
      type: "MINUTES",
      name: "mins",
      label: t("adminPageActivityFormStartTime"),
    },
    {
      type: "ACTIVITY_TYPE",
      name: "activityTypeID",
      label: t("adminPageActivityFormSelectActivityType"),
    },
    {
      type: "OPTIONS",
      name: "participationType",
      label: t("adminPageActivityFormSelectParticipationType"),
      options: participationTypeOptions,
    },
    {
      type: "MEMBERSHIP_PLAN",
      name: "membershipPlanIDs",
    },
  ];

  if (forAddingNewItem)
    return (
      <NewCard>
        <CustomButton onClick={addItem}>{t("clickHereToAddNew")}</CustomButton>
      </NewCard>
    );

  return (
    <Card>
      <ProfileContainer>
        <Image
          src={getImageURL(item.activityType.image, false)}
          alt="Activity Type Image"
        />
        <ProfileText>
          <Name>
            {t("adminPageActivityType")}: {item.activityType.title}{" "}
          </Name>
        </ProfileText>
      </ProfileContainer>

      <Data>
        <FiledValue>
          {t("adminPageActivityDate")}: {formatDate(item.date)}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageActivitySeats")}: {item.seats}{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageActivityTime")}: {item.hour}:{item.mins}
        </FiledValue>
        <FiledValue>
          {t("adminPageActivityDuration")}: {item.duration} Mins{" "}
        </FiledValue>
        <FiledValue>
          {t("adminPageActivitySeatsBooked")}: {item.seatsBooked}
        </FiledValue>
      </Data>

      <ActionButtons>
        <CustomButton onClick={showBookings}>
          {t("adminPageActivityShowBookings")}
        </CustomButton>
        <CustomButton onClick={editItem}>
          {t("adminPageActivityEditItem")}
        </CustomButton>
      </ActionButtons>
    </Card>
  );

  function showBookings() {
    goTo(`/admin/manage-activity-booking/?id=${item._id}`)();
  }

  function addItem() {
    setForm({
      title: "Add Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          fields={fields}
          path="/admin/activity"
        />
      ),
    });
  }

  function editItem() {
    setForm({
      title: "Edit Item",
      component: (
        <AddOrEditForm
          arrayItems={items}
          setArrayItems={setItems}
          index={index}
          fields={fields}
          values={item}
          path="/admin/activity"
        />
      ),
    });
  }
}
