import styled from "styled-components";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useContext, useEffect, useState } from "react";
import { serverLine } from "../../../../controllers/serverLine";
import LoadingSection from "../../../helperComponents/LoadingSection";
import Context from "../../../../Context";
import { useTranslation } from "react-i18next";
import getImageURL from "../../../../controllers/getImageURL";
import calculateAge from "../../../../controllers/calculateAge";
import OrderCard from "../shop/OrderCard";
import ActivityBookingCard from "../activity/ActivityBookingCard";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px 0;
  gap: 50px;
  background-color: #000;
  color: #fff;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const InfoSection = styled.div`
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #c9d400;
`;

const Title2 = styled.div`
  font-size: 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
`;

const ImageSection = styled.img`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  object-fit: cover;
  height: 300px;
  width: 300px;
`;

const SmallHeading = styled.div`
  color: var(--accentColor);
  font-size: 23px;
`;

const Section = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export default function UserPage() {
  const { loggedInUser } = useContext(Context);
  const { t } = useTranslation();
  const [item, setItem] = useState(null);

  useEffect(() => {
    let lastPart = window.location.pathname.split("/");
    let username = lastPart[lastPart.length - 1];
    serverLine.get(`/user/?username=${username}`).then(setItem);
  }, []);

  if (!item || !loggedInUser)
    return (
      <LoggedInBoilerplate>
        <LoadingSection />
      </LoggedInBoilerplate>
    );

  return (
    <LoggedInBoilerplate>
      <Container>
        <InfoSection>
          <Section>
            <Title>
              {item.userData.firstName} {item.userData.lastName}
            </Title>
            <Title2>{item.userData.email}</Title2>
            <Title2>{item.userData.phoneNumber}</Title2>
            <Title2>Age: {calculateAge(item.userData.dateOfBirth)}</Title2>
          </Section>

          <Section>
            <SmallHeading>Activities</SmallHeading>
            <List>
              {item.activityBookings.map((item) => (
                <ActivityBookingCard item={item} />
              ))}
            </List>
          </Section>

          <Section>
            <SmallHeading>Shopping History</SmallHeading>
            <List>
              {item.orders.map((item) => (
                <OrderCard item={item} />
              ))}
            </List>
          </Section>
        </InfoSection>

        <RightSection>
          <ImageSection
            src={getImageURL(loggedInUser.profileImage, true)}
          ></ImageSection>
        </RightSection>
      </Container>
    </LoggedInBoilerplate>
  );
}
