import styled from "styled-components";

import CustomButton from "../../../helperComponents/CustomButton";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import InputCard from "../../../helperComponents/InputCard";
import logout from "../../../../controllers/logout";
import LoggedInBoilerplate from "../LoggedInBoilerplate";
import { useContext } from "react";
import Context from "../../../../Context";
import adminAccess from "../../../../data/adminAccess";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 90vw;
  align-items: center;
  gap: 50px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export default function SettingsPage() {
  const { loggedInUser } = useContext(Context);
  const { t } = useTranslation();

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.email);
  }

  return (
    <LoggedInBoilerplate showBackgroundImage={true}>
      <Container>
        <InputCard title={t("settingsPageTitle")} submitButtonText={null}>
          <Inputs>
            <CustomButton onClick={logout}>{t("logout")}</CustomButton>
            {/* <CustomButton>Contact Support</CustomButton> */}
            <CustomButton href="/edit-profile">{t("editProfile")}</CustomButton>
            {isAdmin ? (
              <CustomButton href="/admin/manage-health-checkups">
                {t("adminPage")}
              </CustomButton>
            ) : null}

            {loggedInUser.isEmployee ? (
              <CustomButton href="/employee-dashboard">
                {t("employeeDashboard")}
              </CustomButton>
            ) : null}
          </Inputs>
        </InputCard>
      </Container>
    </LoggedInBoilerplate>
  );
}
