import { useContext, useEffect, useState } from "react";
import AdminPageBoilerplate from "./AdminPageBoilerplate";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import CustomButton from "../../../helperComponents/CustomButton";
import styled from "styled-components";
import MessageBox from "../../../helperComponents/MessageBox";
import { useTranslation } from "react-i18next";
import Context from "../../../../Context";
import CustomMasonry from "../../../helperComponents/CustomMasonry";
import EmployeeDashboardBoilerplate from "./employeeDashboard/EmployeeDashboardBoilerplate";

const List = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 100px;
`;

export default function AdminCardsList({
  title,
  CardComponent,
  path,
  allowCreation,
  dashboardType = "ADMIN_DASHBOARD",
}) {
  const { isMobile } = useContext(Context);
  const [items, setItems] = useState(null);
  const [offset, setOffset] = useState(-1);
  const [moreContentIsLoading, setMoreContentIsLoading] = useState(false);

  useEffect(() => {
    loadFirstBatch(path);
  }, [path]);

  let ParentComponent = AdminPageBoilerplate;

  if (dashboardType == "EMPLOYEE_DASHBOARD")
    ParentComponent = EmployeeDashboardBoilerplate;

  if (!items)
    return (
      <ParentComponent title={title}>
        <LoadingSection />
      </ParentComponent>
    );

  let toRender = items.map((item, index) => (
    <CardComponent
      dashboardType={dashboardType}
      item={item}
      index={index}
      items={items}
      setItems={setItems}
    />
  ));

  if (allowCreation) {
    toRender.push(
      <CardComponent
        dashboardType={dashboardType}
        forAddingNewItem={true}
        items={items}
        setItems={setItems}
      />
    );
  }

  return (
    <ParentComponent title={title}>
      {toRender.length ? (
        <CustomMasonry list={toRender} maxCol={4} colsOnMobile={1} />
      ) : null}
      <br /> <br />
      {getShowMoreButton()}
    </ParentComponent>
  );

  function getShowMoreButton() {
    if (!items.length)
      return (
        <MessageBox style={{ width: isMobile ? "90vw" : "200px" }}>
          No items found
        </MessageBox>
      );

    return (
      <CustomButton
        style={{ width: "100px", background: "var(--bgColor)" }}
        onClick={showMore}
      >
        {moreContentIsLoading ? "Loading..." : "Show More"}
      </CustomButton>
    );
  }

  async function showMore() {
    if (moreContentIsLoading) return false;
    setMoreContentIsLoading(true);
    await doQuery();
    setMoreContentIsLoading(false);
  }

  async function doQuery() {
    setOffset(offset + 1);
    let newItems = await serverLine.get(`/admin/${path}/?offset=${offset}`);
    setItems([...items, newItems]);
  }

  async function loadFirstBatch(path) {
    setItems(null);
    serverLine.get(`/admin/${path}/`).then(setItems);
  }
}
