import styled from "styled-components";

import CustomButton from "../../../helperComponents/CustomButton";
import WithBackground from "../../../core/boilerplate/WithBackground";
import LoggedInHeader from "../LoggedInHeader";
import InputCard from "../../../helperComponents/InputCard";
import { RiAdminLine } from "react-icons/ri";
import { useContext, useEffect, useState } from "react";
import CustomInput from "../../../helperComponents/CustomInput";
import LoadingSection from "../../../helperComponents/LoadingSection";
import MaterialInput from "../../../helperComponents/MaterialInput";
import ImagePicker from "../../../editors/ImagePicker";
import PrimaryButton from "../../../helperComponents/PrimaryButton";
import submitData from "../../../../controllers/request/submitData";
import Context from "../../../../Context";
import ScheduleDatePicker from "../health/scheduleCheckup/ScheduleDatePicker";
import ScheduleHourPicker from "../health/scheduleCheckup/ScheduleHourPicker";
import ScheduleSlotPicker from "../health/scheduleCheckup/ScheduleSlotPicker";
import CustomToggle from "../../../helperComponents/CustomToggle";
import ActivityTypePicker from "./ActivityTypePicker";
import EmployeePicker from "./EmployeePicker";
import ScheduleMinutesPicker from "../health/scheduleCheckup/ScheduleMinutesPicker";
import ScheduleDayPicker from "../health/scheduleCheckup/ScheduleDayPicker";
import CategoryEditor from "./CategoryEditor";
import CategorySelector from "./CategorySelector";
import OptionPicker from "../../../helperComponents/OptionPicker";
import OptionMultiPicker from "../../../helperComponents/OptionMultiPicker";
import { useTranslation } from "react-i18next";
import StoreImagesEditor from "./store/StoreImagesEditor";
import MembershipPlanPicker from "./MembershipPlanPicker";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 50px;
  padding: 50px 20px;
`;

/*

 Supported inputs
  * String
  * Long String
  * Number
  * Date
  * Time
  * Image
  * File

*/

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export default function AddOrEditForm({
  fields,
  values,
  path,
  arrayItems,
  setArrayItems,
  index,
  dashboardType,
  additionalData = {},
}) {
  //additionalData is used when for buying activity for logged out user
  const { setForm } = useContext(Context);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (values) {
      generateState();
    }
  }, []);

  // console.log(values, state);

  function generateState() {
    let newState = {};

    for (let item of fields) {
      let fieldName = item.name;
      let value = getNestedValue(fieldName, values);
      newState = setNestedValue(fieldName, value, newState);
    }

    setState(newState);
  }

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <Inputs>{getInputs()}</Inputs>
      <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
    </Container>
  );

  function getInputs() {
    let inputs = [];

    for (let item of fields) {
      let inputFieldValue = getNestedValue(item.name, state);
      let onChangeFunction = updateState(item.name);

      console.log(inputFieldValue);

      if (item.type == "STRING") {
        inputs.push(
          <MaterialInput
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={onChangeFunction}
          />
        );
      } else if (item.type == "LONG_STRING") {
        inputs.push(
          <MaterialInput
            multiline={true}
            rows={3}
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name)}
          />
        );
      } else if (item.type == "REALLY_LONG_STRING") {
        inputs.push(
          <MaterialInput
            multiline={true}
            rows={7}
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name)}
          />
        );
      } else if (item.type == "NUMBER") {
        inputs.push(
          <MaterialInput
            type="number"
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name)}
          />
        );
      } else if (item.type == "DATE") {
        inputs.push(
          <MaterialInput
            type="date"
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name)}
          />
        );
      } else if (item.type == "DATE_TIME") {
        inputs.push(
          <MaterialInput
            type="dateAndTime"
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name)}
          />
        );
      } else if (item.type == "STORE_IMAGES") {
        inputs.push(
          <StoreImagesEditor
            forPerson={item.forPerson}
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name, true)}
          />
        );
      } else if (item.type == "IMAGE") {
        inputs.push(
          <ImagePicker
            forPerson={item.forPerson}
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name, true)}
          />
        );
      } else if (item.type == "FILE") {
        inputs.push(
          <ImagePicker
            isFile={true}
            value={inputFieldValue}
            label={item.label}
            placeholder={item.placeholder}
            onChange={updateState(item.name, true)}
          />
        );
      } else if (item.type == "UPCOMING_DATES") {
        inputs.push(
          <>
            <br />
            <ScheduleDatePicker
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "HOUR_RANGE") {
        inputs.push(
          <>
            <br />
            <ScheduleHourPicker
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "HOUR") {
        inputs.push(
          <>
            <br />
            <ScheduleHourPicker
              disableRange={true}
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "DAYS") {
        inputs.push(
          <>
            <br />
            <ScheduleDayPicker
              disableRange={true}
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "MINUTES") {
        inputs.push(
          <>
            <br />
            <ScheduleMinutesPicker
              hourValue={state.hour}
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "HEALTH_CHECKUP_SLOT") {
        inputs.push(
          <>
            <br />
            <ScheduleSlotPicker
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              hour={state.hour}
              date={state.date}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "BOOLEAN") {
        inputs.push(
          <>
            <br />
            <CustomToggle
              name={item.label}
              value={inputFieldValue}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "ACTIVITY_TYPE") {
        inputs.push(
          <>
            <br />
            <ActivityTypePicker
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "EMPLOYEE") {
        if (dashboardType !== "EMPLOYEE_DASHBOARD")
          inputs.push(
            <>
              <br />
              <EmployeePicker
                value={inputFieldValue}
                label={item.label}
                placeholder={item.placeholder}
                onChange={updateState(item.name, true)}
              />
            </>
          );
      } else if (item.type == "MEMBERSHIP_PLAN") {
        inputs.push(
          <>
            <br />
            <MembershipPlanPicker
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "CATEGORY_SELECTOR") {
        inputs.push(
          <>
            <br />
            <CategorySelector
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "CATEGORY_EDITOR") {
        inputs.push(
          <>
            <br />
            <CategoryEditor
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "OPTIONS") {
        inputs.push(
          <>
            <br />
            <OptionPicker
              options={item.options}
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      } else if (item.type == "MULTI_OPTIONS") {
        inputs.push(
          <>
            <br />
            <OptionMultiPicker
              options={item.options}
              value={inputFieldValue}
              label={item.label}
              placeholder={item.placeholder}
              onChange={updateState(item.name, true)}
            />
          </>
        );
      }
    }

    return inputs;
  }

  function updateState(fieldName, isNotEvent) {
    return (e) => {
      let val = null;

      if (isNotEvent) {
        val = e;
      } else {
        val = e.target.value;
      }

      setState(setNestedValue(fieldName, val, state));
    };
  }

  function onSubmit() {
    if (values) return updateItem();
    return addItem();
  }

  function addItem() {
    submitData({
      path: path,
      method: "post",
      data: { ...state, ...additionalData },
      setLoading,
      onSuccess: addItemToArray,
    });
  }

  function addItemToArray(newItem) {
    setForm(null);
    let items = [newItem, ...arrayItems];
    setArrayItems(items);

    // window.popupAlert("Item Added");
  }

  function updateItemInArray(newItem) {
    setForm(null);
    let newItems = [...arrayItems];
    newItems[index] = newItem;
    setArrayItems(newItems);
    // window.popupAlert("Item Updated");
  }

  function updateItem() {
    submitData({
      path: path,
      method: "patch",
      data: { changes: state, id: values._id },
      setLoading,
      onSuccess: updateItemInArray,
    });
  }

  function setNestedValue(inputField, value, theObject) {
    const fields = inputField.split("."); // Split the inputField by '.'
    let newState = { ...theObject };
    let current = newState;

    fields.forEach((field, index) => {
      if (index === fields.length - 1) {
        // If it's the last field, set the value
        current[field] = value;
      } else {
        // If the field doesn't exist, create an empty object
        if (!current[field]) {
          current[field] = {};
        }
        // Move to the next level in the object
        current = current[field];
      }
    });

    return newState;
  }

  function getNestedValue(inputField, theObject) {
    const fields = inputField.split("."); // Split the inputField by '.'

    let current = { ...theObject };

    if (inputField == "reportFile") console.log(fields);

    fields.forEach((field) => {
      if (current) {
        // If the field doesn't exist, create an empty object
        if (!current[field]) {
          current = null;
        } else {
          current = current[field];
        }

        // Move to the next level in the object
      }
    });

    return current;
  }
}
