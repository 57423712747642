import { useEffect, useState } from "react";
import BigToggle from "../../../helperComponents/BigToggle";
import LoadingSection from "../../../helperComponents/LoadingSection";
import { serverLine } from "../../../../controllers/serverLine";
import { useTranslation } from "react-i18next";

export default function MembershipPlanPicker({ value, onChange }) {
  const [items, setItems] = useState(null);
  const { t } = useTranslation();

  if (!value) value = [];

  useEffect(() => {
    serverLine.get("/admin/membership-plans").then(setItems);
  }, []);

  if (!items) return <LoadingSection />;

  return (
    <BigToggle
      title={t("adminPageActivityFormSelectMembershipType")}
      options={items.map((item) => getItem(item))}
    ></BigToggle>
  );

  function getItem(item) {
    return {
      onClick: () => {
        if (value.includes(item._id)) {
          let index = value.indexOf(item._id);
          let newVal = [...value];
          newVal.splice(index, 1);
          onChange(newVal);
        } else {
          let newVal = [...value];
          newVal.push(item._id);
          onChange(newVal);
        }
      },
      isSelected: value.includes(item._id),
      label: `${item.name}`,
    };
  }
}
