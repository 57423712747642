import { useTranslation } from "react-i18next";
import AdminCardsList from "../AdminCardsList";
import ManageAnnouncementCard from "./ManageAnnouncementCard";

export default function ManageAnnouncementPage({ dashboardType }) {
  const { t } = useTranslation();

  return (
    <AdminCardsList
      title={t("adminPageAnnouncementsTitle")}
      path="announcements"
      CardComponent={ManageAnnouncementCard}
      dashboardType={dashboardType}
      allowCreation={true}
    />
  );
}
